window.VWFS = window.VWFS || {};
VWFS.CheckoutManager = function (checkoutButton) {
    console.debug('INFO CheckoutManager.js loaded')

    var spinnerTypes = {
        disclaimer: 0,
        checkoutButton: 1
    };

    var spinner;
    var spinnerType;
    var errorModal = document.querySelector('.js-error-modal')
    var servletURL;

    /**
     * OPEN LINK
     */
    this.openLink = function () {
        if (checkoutButton) {
            setSpinner()
            // GET URL FROM DATA-ATTRIBUTES
            servletURL = checkoutButton.getAttribute('data-checkout-path') + '.checkoutnonecache.json'
            // ADD CLICK-EVENT
            checkoutButton.addEventListener('click', onCheckoutButtonClick)
        }
    }

    /**
     * Set spinner and spinner type
     */
    var setSpinner = function () {
        if (checkoutButton) {
            // GET SPINNER
            if (checkoutButton.querySelector(".js-checkout-button-spinner")) {
                //use spinner on the checkout button
                spinner = checkoutButton.querySelector('.js-checkout-button-spinner')
                spinnerType = spinnerTypes.checkoutButton
            } else {
                // else use the default
                spinner = document.querySelector('#id-modal-spinner')
                spinnerType = spinnerTypes.disclaimer
            }
        }
    }
    /**
     * EVENT > ON CHECKOUT BUTTON CLICK
     */
    var onCheckoutButtonClick = function (event) {
        event.preventDefault()
        event.stopPropagation()

        // SHOW SPINNER AND DISABLE BUTTON
        hideSpinner(false)
        checkoutButton.setAttribute('disabled', true)
        sendCheckoutButtonNormal()
    }

    var sendCheckoutButtonNormal = function () {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', onStorefrontServiceLoad)
        xhr.open('GET', servletURL, true)
        xhr.send()
    }

    /**
     * EVENT > ON STOREFRONT SERVICE LOAD
     */
    var onStorefrontServiceLoad = function (event) {
        var response = getResponse(event)
        if (response) {
            // HIDE SPINNER
            hideSpinner(true)
            var url = getURL(response)
            if (VWFSUtils.isIOS()) {
                console.debug('INFO: run on iOS')
                checkoutButton.setAttribute('target', '_self')
            }
            // ADD SATELLITE PARAMETER - de_apid (some kind of affiliate id)
            if (typeof _satellite !== "undefined" && _satellite) {
                let deApid = _satellite.getVar('de_apid')
                if (deApid) {
                    url = VWFSUtils.addUrlParam(url, 'apid', deApid)
                }
            }
            // OPEN URL
            openURL(url)
        }
    }

    /**
     * ERROR HANDLING
     */
    var showErrorModal = function () {
        hideSpinner(true);
        checkoutButton.removeAttribute('disabled')
        errorModal.setAttribute('aria-hidden', 'false')
    }

    var hideSpinner = function (hide) {
        if (spinnerType === spinnerTypes.disclaimer) {
            spinner.setAttribute('aria-hidden', hide)
            spinner.querySelector('.c-spinner--full-page').setAttribute('aria-busy', !hide.toString())
        } else {
            spinner.hidden = hide
        }
    }



    var getResponse = function (event) {
        if (event.target.readyState === XMLHttpRequest.DONE) {
            if (event.target && event.target.status === 200 && event.target.response && JSON.parse(event.target.response) != null) {
                let response = JSON.parse(event.target.response)
                if (response && !response.errors) {
                    return response
                } else {
                    console.debug('Error: CheckoutManager.js: Response is missing or contains errors.')
                    showErrorModal()
                }
            } else {
                console.debug('Error: CheckoutManager.js: Request failed: ' + event.target.status + ' / ' + event.target.responseText)
                showErrorModal()
            }
        }
        return null
    }

    var getURL = function (response) {
        var url;
        if (VWFSUtils.getObjectPathExists(response, "data.checkout.salesChannel.entryPointUrl") && response.data.checkout.salesChannel.entryPointUrl.length > 0) {
            // 1. check data.checkout.salesChannel.entryPointUrl
            url = response.data.checkout.salesChannel.entryPointUrl;
        } else if (VWFSUtils.getObjectPathExists(response, "salesChannel.entryPointUrl") && response.salesChannel.entryPointUrl.length > 0) {
            // 2. check salesChannel.entryPointUrl
            url = response.salesChannel.entryPointUrl;
        } else if (VWFSUtils.getObjectPathExists(response, "data.checkout.url") && response.data.checkout.url.length > 0) {
            // 3. check data.checkout.url for Market CZ
            url = response.data.checkout.url;
        } else {
            // as default use the normal href as url
            url = checkoutButton.getAttribute('data-href')
        }
        if (url && url.includes("storefrontID")) {
            let id
            if(response.data && response.data.checkout && response.data.checkout.id) {
                id = response.data.checkout.id;
            }
            if (id === undefined && response.id) {
                id = response.id;
            } else {
                // if there is no id, show Error Modal
                console.debug("Error: CheckoutManager.js: ID is missing or it's empty.");
                showErrorModal();
                return;
            }
            url = url.replace("storefrontID", id);
        }
        return url;
    }

    var openURL = function (url) {
        if (url && url.length > 0) {
            // handling in case Button has disclaimer
            if (checkoutButton.getAttribute('data-disclaimer-ref') != null) {
                new VWFS.DisclaimerManager(checkoutButton).openDisclaimer(url)
            } else if (checkoutButton.hasAttribute('target') && checkoutButton.getAttribute('target') === '_blank') {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        } else {
            console.debug("Error: CheckoutManager.js: URL is missing or it's empty.")
            showErrorModal()
        }
    }
};
